.our-projects__card-wrapper {
    width: 408px !important;
    margin: auto !important;
    display: block !important;
}

@media screen and (max-width: 1280px) {
    .our-projects__card-wrapper {
        width: 339px !important;
    }
}