.container {
  padding-top: 64px;
  /* padding-bottom: 60px; */
  background: #f8fff9;
}

.main_title {
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
}

.main_subtitle {
  margin-top: 22px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212325;
  opacity: 0.7;
}

.banner_wrapper {
  max-width: 1206px;
  height: 398px;
  margin: auto;
  filter: drop-shadow(0px 0px 6px rgba(0, 70, 7, 0.27));
  border-radius: 8px;
  margin-top: 49px;
  position: relative;
}

.banner {
  width: 100%;
  max-height: 406px;
  border-radius: 8px;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .banner_wrapper {
    max-width: 90%;
    height: auto;
  }
}