.about-connect-info-form-containers {
  background: white !important;
  padding: 20px;
  position: relative;
  height: 562px;
}

.about-connect-info-container {
  display: flex;
  justify-content: center;
  margin: 54px 0px 0px 55px;
  padding-bottom: 100px;
  margin: 0px;
  padding-bottom: 0px;
}

.about-connect-info-body {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* flex-direction: column; */
}

.about-connect-info-details-container {
  background: #0f461e;
  padding: 45px;
  flex: 1;
}

.about-connect-info-form-container {
  padding: 45px;
  flex: 1;
  background: #ffffff;
}

.about-connect-info-form-container {
  padding: 45px;
  flex: 1;
  background: #ffffff;
}

.about-connect-info-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  margin-bottom: 20px;
  color: #090909;
}

.about-connect-info {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  margin-bottom: 20px;
  color: #ffffff;
}

.about-connect-info-info-container {
  display: flex;
  margin: 24px;
  background: red;
}

.about-connect-info-info-icon {
  margin-right: 24px;
}

.about-connect-info-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Sec */

  color: #ffffff;
}

.about-connect-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  /* or 23px */

  letter-spacing: 0.05em;

  /* Sec */

  color: #ffffff;
}

.about-connect-info-form-input-container {
  padding: 10px 0;
}

.about-connect-info-form-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Character / Title .85 */

  color: rgba(0, 0, 0, 0.85);
  text-align: left;
  /* margin-bottom: 5px; */
}

.about-connect-info-form-input {
  padding: 8px 12px;

  width: 95%;
  height: 30px;

  /* Neutral/1 */

  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}

.about-connect-info-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  gap: 11px;
  margin-top: 50px;
  flex: 1;
  height: 45px;
  color: #ffffff;
  /* Primary */

  background: #209541;
  border-radius: 4px;
  border: none;
}

.about-connect-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}

.about-float {
  position: fixed;
  width: 60px;
  height: 200px;
  bottom: 400px;
  right: 220px;
  border-radius: 50px;
  text-align: center;
  z-index: 2000;
  top: 100px;
  padding: 30px;
}

.about-close-button {
  float: right;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
}

.about-car-image {
  height: 100%;
  padding-top: 20px;
}

.about-hide {
  display: none;
}

.about_image {
  width: 69px;
  height: 45px;
}

.icon {
  flex-direction: row;
  display: flex;
  border-right: 3px solid #ffab26;
  height: 100px;
  padding-right: 30px;
  align-items: center;
}

.about-heading {
  margin-top: 10px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 35px;
  color: #212325;
}

/* .close-car-image{
    position: relative;
      top: 624px;
      margin-left: -87px;
      cursor: pointer;
  } */

/* @media (max-width: 850px) {
  .about-connect-info-form-container {
    padding: 16px;
    margin-left: 70px;
  }
  .about-connect-info-body {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex-direction: column;
    width: 270px;
    align-items: flex-start;
  }
  .about-cantactus-car-image {
    width: 100%;
    height: auto;
  }
  .about-input-box-width {
    padding: 12px;
  }
  .about-connect-info-header {
    margin-left: 10px;
  }

  .about-connect-info-container {
    width: 225px;
    display: flex;
    margin: auto;
  }
} */
/* 
@media (max-width: 768px) {
  .about-title {
    max-width: 274px !important ;
  }
  .about-connect-info-form-container {
    padding: 16px;
    margin-left: -70px !important;
    width: 320px;
  }
}
@media (max-width: 375px) {
  .about-connect-info-form-container {
    padding: 16px;
    margin-left: 5px !important;
    width: auto;
  }
} */

@media (max-width: 1280px) {
  .about-connect-info-form-containers {
    /* height: 534px; */
    height: 513px;
  }
}

@media (max-width: 1024px) {
  .about-title {
    max-width: 528px;
  }

  .about-connect-info-details-container {
    padding: 8px !important;
  }

  /* .lesser{
    position: relative;
      left: 470px !important;
      top: 240px !important;
  } */
}

@media (max-width: 778px) {
  .about-container {
    display: flex;
    flex-direction: column;
    width: 95% !important;
  }

  .satisfaction-tagline {
    width: 100% !important;
  }

  .about-title {
    margin-left: 5px !important;
    max-width: 95% !important;
    text-align: left;
    padding: 10px;
    margin-top: 0px !important;
  }

  .about-taglines {
    width: 100% !important;
    text-align: justify;
  }

  .about-title2 {
    width: 100% !important;
    font-size: 18px !important;
  }

  .about-title3 {
    width: 100% !important;
    font-size: 18px !important;
    margin-top: 38px !important;
  }

  .about-tagline2 {
    width: 100% !important;
  }

  .about-tagline3 {
    width: 100% !important;
  }

  .about-title4 {
    width: 100% !important;
  }

  .about-tagline4 {
    width: 100% !important;
  }

  .connect-with-us-content {
    width: 98% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0 !important;
  }

  .about-connect-info-form-container {
    padding: 5px;
    margin-left: 1px !important;
    width: 100%;
    margin: 10px;
  }

  .about-connect-info-details-container {
    padding: 0px !important;
    width: 100%;
  }

  .about_image {
    width: 40px;
    height: 40px;
  }

  .icon {
    flex-direction: row;
    display: flex;
    border-right: 3px solid #ffab26;
    height: 60px;
    padding-right: 10px;
    align-items: top !important;
  }

  .about-heading {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 24px;
    color: #212325;
  }
}

/* @media (max-width: 320px) {
  .about-connect-info-details-container {
    padding: 10px !important;
  }
} */

@media (max-width: 1440px) {
  .about-connect-info-body {
    /* margin-left: 55px; */
  }
}