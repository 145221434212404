.page-section {
  /* max-width: 1440px; */
  margin: auto;
  /* border: 1px solid #ccc; */
}
/* @media (max-width: 778px) {
  .page-section {
    background-image: none;
  }
} */
