.contact-info-container {
  display: flex;
  justify-content: center;

  margin-bottom: 100px;
  /* background-color: #fff; */
}
.contact-info-body {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px 10px 10px 10px;
  /* flex-direction: column; */
  margin-top: 70px;
}

.contact-info-details-container {
  background: #0f461e;
  padding: 45px;
  padding-bottom: 0;
  flex: 1;
  border-radius: 10px 0px 0px 10px;
}
.contact-info-form-container {
  padding: 45px;
  padding-bottom: 0;
  flex: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 0 10px 10px 0;
}
.contact-info-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 10px;
  color: #fff;
  margin: 0;
  margin-bottom: 14px;
}

.contact-info-info-container {
  display: flex;
  margin: 15px;
}

.contact-info-info-icon {
  margin-right: 24px;
}
.contact-info-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Sec */

  color: #fff;
}
.contact-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 30px;
}
.contact-info-form-input-container {
  padding: 10px 0;
}

.contact-info-form-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Character / Title .85 */

  color: rgba(0, 0, 0, 0.85);
  /* margin-bottom: 5px; */
}

.contact-info-form-input {
  padding: 8px 12px;

  width: 95%;
  height: 30px;

  /* Neutral/1 */

  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}

.contact-info-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  gap: 11px;
  margin-top: 50px;
  flex: 1;
  cursor: pointer;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  /* Primary */
  color: #ffffff;
  background: #209541;
  border-radius: 4px;
  border: none;
}
.contact-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}

.contact-info-sub-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #fff;
  margin-bottom: 17px;
  opacity: 0.8;
}
.contact-info-container-social-icon {
  display: flex;
  justify-content: center;
}

.contact-info-form-container {
  background: #fff;
}

@media (max-width: 950px) {
  @media (max-width: 430px) {
    .contact-info-body {
      width: 100% !important;
      margin-top: 55px;
    }
    .contact-info-header {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;

      margin: 10px;
      color: #fff;
    }
    .contact-info-details-container {
      padding: 14px 4px;
    }
    .contact-info-info-container {
      margin: 10px;
    }
    .contact-info-sub-header {
      display: none;
    }
    .contact-info-container-social-icon {
      display: none;
    }
    .contact-info-form-container {
      background-color: #fbfff5;
      border: 1px solid #ccc;
    }
    .contact-info-form-input {
      background-color: #fbfff5;
    }
    .contact-info-form-input input {
      background-color: #fbfff5;
    }
    .contact-info-details-container {
      border-radius: 10px 10px 0px 0px;
    }
    .contact-info-body {
      border: none;
    }
  }

  @media (max-width: 375px) {
    .contact-info-body {
      width: 306px;
    }
  }
  .contact-info-body {
    flex-direction: column;
  }
}
/* @media (max-width:1228px) {
  .contact-info-details-container {
    padding: 17px;
  }
  .contact-info-form-container {
    padding: 17px;
  }
} */
