.bc-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #209541;
  margin-right: 8px;
  cursor: pointer;
  text-transform: capitalize;
}
.bc-link-current {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .bread-conatiner {
    margin-left: 2% !important;
  }
}
@media (max-width: 778px) {
  .bc-link {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #209541;
    margin-right: 5px;
    cursor: pointer;
  }
  .bread-conatiner {
    margin-left: 2% !important;
    align-items: center !important;
  }
  .bc-link-current {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 1600px) and (max-width: 2500px) {
  .bread-conatiner {
    margin-left: 2% !important;
  }
}
