/*  Property Details styling */
.container {
  display: flex;
  align-items: center;
}
.main_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide {
  height: 25px;
  background: black;
  color: white;
  border-radius: 30px;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.06em;
  color: #f8fff9;
  /* display: flex;
  align-items: center; */
  margin: 20px auto;
  display: inline-block;
}
.slide_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
}
.title {
  /* width: -webkit-fill-available; */
  /* height: 16px; */
  height: 20px;
  /* display: flex; */
  /* align-items: center; */
}
.horizontal_line {
  min-width: 14px;
  max-width: 480px;
  height: 0px;
  border: 1px dashed #ffffff;
  margin-left: 9px;
}
.details {
  display: inline-block;
}

/*  Distance Logo styling */
.distance_logo_container {
  position: relative;
  width: 60px;
  height: 48.1px;
}
.distance {
  position: absolute;
  top: 50%;
  right: 6.6px;
  transform: translateY(-50%);
  width: 35.94px;
  height: 36.13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 9.85792px;
  letter-spacing: 0.06em;
  background: #fff;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
}
distance_logo_container svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 46.68px;
  height: 56.89px;
}

@media screen and (max-width: 768px) {
  .main_wrapper {
    display: flex;
    align-items: center;
    width: 100% !important;
  }
  .slide{
    width: 100% !important;
  }
}
