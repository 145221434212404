.container {
  max-width: 1320px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 408px);
  justify-content: space-around;
}
.item_wrapper{
  margin: 15px auto;
}
@media screen and (max-width:768px) {
  .container{
  grid-template-columns: repeat(auto-fill, 339px);
  }
}