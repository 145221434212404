.why-invest-in-mysore-card-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 141.7%;
  /* identical to box height, or 26px */
  text-align: right;
  letter-spacing: 0.05em;
  margin-top: 20px;
  color: #000000;
  margin-left: 2%;
}

.why-invest-in-mysore-card-sub-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 168.7%;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
}

.why-invest-points-container-larg {}

.why-invest-points-container-small {
  display: none;
}

.why-invest-cards-container {
  background-image: url("../../assets/images/palace1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.why-invest-in-mysure-person-image {
  width: 500px;

  position: relative;
  bottom: 45px;
  height: 525px;
}

@media (max-width: 1063px) {
  .why-invest-cards-container {
    flex-direction: column;
  }

  .why-invest-points-container-small {
    display: block;
  }

  .why-invest-points-container-larg {
    display: none;
  }
}

@media (max-width: 430px) {
  /* .why-invest-in-mysore-card-title{
       
  }
  .page-section-header-title{
    font-weight: 500;
    font-size: 28px !important;
    line-height: 147.7%;
  }
  .why-invest-in-mobile{
    margin: 20px !important;
  }
  */

  .why-invest-cards-container {
    background-image: none;
  }

  .why-invest-in-mysore-card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .why-invest-in-mysure-person-image {
    width: 100%;
    position: relative;
    bottom: 4px;
    text-align: center;
    height: 405px;
  }
}

@media (min-width: 900px) and (max-width: 1150px) {
  .why-invest-in-mysure-person-image {
    width: 400px;

    position: relative;
    bottom: 45px;
    height: 525px;
  }

  .why-invest-cards-container {
    background-image: url("../../assets/images/palace1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }
}