@keyframes marquee {
  0% {
    left: -33.33%;
  }

  100% {
    left: -100%;
  }
}

.animateRightToLeft {
  position: relative;
  width: 216%;
  animation: marquee 20s linear infinite;
  top: 0;
}

.animateRightToLeft:hover {
  animation-play-state: paused;
}

.treanding-projects {
  position: relative;
  z-index: 9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.trending-projects-background {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  /* object-fit: cover; */
}
.trending-projects-background-responsive {
  width: 100%;
  position: absolute;
  bottom: -26px;
  right: 0;
  display: none;

}
.trending-project-card-title {
  /* Farm Land Daksha Chandana */

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* or 117% */

  /* primary */
  color: #212325;
}

.trending-project-card-sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: rgba(0, 0, 0, 0.4);
}

/* @media (max-width: 750px) {
} */
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel .control-before.control-arrow:before {
  border-left: 8px solid #fff;
}

.trendingProjects-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  left: -55.08px;
  background: #ffffff57;
  cursor: pointer;
}

.trendingProjects-carousal-right-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  right: -9px;
  background: #ffffff57;
  cursor: pointer;
}

.trendingProjects-slider-small-action-holder {
  display: none;
}

.trending_projects_container {
  overflow-x: hidden;
  max-width: 100%;
  margin: auto;
}

.trending_items_container {
  /* width: 2560px; */
  /* width: 200%; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* transform: translateX(200%); */
}

.trending_items1,
.trending_items2 {
  /* width: 1277px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
}

.trending_items1 {
  /* animation-name: rightToLeft1;
  animation-duration: 14.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; */
}

.trending_items2 {
  /* animation-name: rightToLeft2;
  animation-duration: 14.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; */
}

@media (max-width: 778px) {
  .treanding-projects {
    max-height: 509px;
  }
  .trending-projects-background{
    display: none;
  }
  .trending-projects-background-responsive{
    display: block;
  }
  .trendingProjects-slider-small-action-holder {
    margin-top: 43px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .trendingProjects-carousal-left-chevron-btn-responsive {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
  }

  .trendingProjects-carousal-right-chevron-btn-responsive {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #ffffff57; */
    background: #fff;
    cursor: pointer;
  }

  @keyframes marquee_mobile {
    0% {
      left: 0;
    }

    100% {
      left: -400%;
    }
  }

  .animateRightToLeft {
    position: relative;
    width: 1200%;
    animation: marquee_mobile 10s linear infinite;
    top: 0;
  }
}
