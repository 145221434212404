.container {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.wrapper {
  margin-left: 19px;
  margin-right: 19px;
}
.prev_btn {
  rotate: 180deg;
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  left: 0;
  cursor: pointer;
}
.next_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
}
/* Recommandation Card */
.card_container {
  padding: 12px 17px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: auto 3px;
  cursor: pointer;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .card_container {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
