.why-us-card {
  display: flex;
  margin-left: 70px;
  margin-bottom: 35px;
}

.why-us {
  min-height: 780px;
  display: flex;
}
.location_icon_with_animation {
  width: 296px;
  position: abosoulte;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.location_img_for_desktop {
  display: block;
}
.location_img_for_responsive {
  display: none;
}
@media (max-width: 750px) {
  .location_img_for_desktop {
    display: none;
  }
  .location_img_for_responsive {
    display: block;
  }
  .why-us-card {
    margin-left: 0;
  }

  .why-us {
    min-height: 1280px;
  }
}
