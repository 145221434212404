.projectDropMenu{
/* width: 15rem; */
position: sticky;
list-style: none;
text-align: start;
/* top:87em; */
/* margin-top: 20rem; */
/* display: flex; */

}

.maindiv{
  background-color: #ffffff;
  width: 13rem;
  height: 10rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000 !important;
  text-transform: capitalize;
  position: fixed;
  opacity: none;
/* background-color: linear-gradient(rgba(rgb(255,255,255),rgba(0,0,0,0.5))); */
}

.maindiv li a:hover{
  background: #DBF6E3;
  color: #209541 !important;
  
}

.projectDropMenu li{
    background-color: #fff;
    cursor: pointer;
  color: #000 !important;
  width: 10rem;
}

.projectDropMenu li a:hover{
    background-color: #DBF6E3;;
}

.projectDropMenu.clicked{
 display: none;
  
}

.nav-items{
    display: flex;
    flex-direction: column;
    position: relative;
    top:20px;
    bottom: 20px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    list-style: none;
    color: #000;
    padding: 5px;
    
}



