@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

@keyframes popup {
  from {
    bottom: -3%;
  }

  to {
    bottom: 5%;
  }
}

.container {
  /* width: 372.02px; */
  height: 100px;
  position: fixed;
  z-index: 1000;
  background: #fff;
  left: 0;
  bottom: 17%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 12px rgba(67, 81, 1, 0.14);
  border-radius: 0px 6px 6px 0px;
}

.item_wrapper {
  padding: 9.5px;
  overflow: hidden;
}

.marketed_by_hidden {
  padding: 9.5px;
  overflow: hidden;
}

.marketed_by_displayed {
  padding: 9.5px;
  overflow: hidden;
}

.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #212325;
  margin-left: 20px;
}

.company_logo {
  width: 76px;
  height: 66px;
  margin-left: 9px;
}

.text_name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-left: 9px;
}

.toggleBtnContainer {
  width: 61px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgba(174, 174, 174, 0.4);
  cursor: pointer;
}

.item {
  display: flex;
  align-items: center;
}
.text_container{
  display: flex;
}
@media (max-width: 778px) {
  .container {
    height: 65px;
    padding: 0;
    justify-content: flex-start;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #212325;
    margin-left: 20px;
  }

  .text_name {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    margin-left: 9px;
  }

  .company_logo {
    width: 46px;
    height: 36px;
    margin-left: 9px;
  }

  .toggleBtnContainer {
    width: 51px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid rgba(174, 174, 174, 0.4);
    cursor: pointer;
  }
}

@media screen and (max-width: 400px) {
  .text_name {
    font-size: 12px;
    display: inline-block;
    margin-left: 12px;
    }

    .item {
      font-size: 12px;
      }
      
      .title {
        font-size: 12px;
        }

}