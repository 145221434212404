.wrapper {
  /* padding: 29px 36px 30px 29px; */
  padding: 29px 36px 0 29px;
}
.input_container {
  display: block;
  width: 100%;
  padding: 32px 45px 30px 45px;
  /* background: rgba(0, 111, 215, 0.03); */
  border-radius: 26px;
  /* background: red; */
}
.inputField {
  height: 50px;
  display: flex;
}
.inputField img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.inputField input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: -webkit-fill-available;
  margin-left: 16px;
  border-radius: 6px;
  padding-left: 15px;
}
.btns_container {
  display: flex;
  justify-content: end;
  margin-top: 21px;
}
.cancel_btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #565656;
  width: 135px;
  height: 40px;
  background: rgba(165, 165, 165, 0.1);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.post_btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #ffffff;
  width: 135px;
  height: 40px;
  border: none;
  background: #209541;
  margin-left: 17px;
  border-radius: 4px;
  cursor: pointer;
}
.cancel_btn:active {
  box-shadow: 0px 2px 20px rgb(67 81 1 / 22%);
}
.post_btn:active {
  box-shadow: 0px 2px 20px rgb(67 81 1 / 22%);
}


@media screen and (max-width: 550px) {
  .wrapper{
    padding:29px 21px 0px 21px;
  }
.inputField input {
  margin-left: 5px;
}
.input_container {
  padding: 32px 12px 30px 12px;
}
}