.container {
  padding-top: 54px;
  padding-bottom: 60px;
  background: #f8fff9;
}

.main_title {
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
}

.main_subtitle {
  margin-top: 22px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212325;
  opacity: 0.7;
}

.filter {
  width: 536px;
  /* height: 60.02px; */
  /* background-color: red; */
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 61px;
}

.filter_item {
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  /* background: rgba(37, 160, 71, 0.19); */
  /* background: #EEEEEE; */
  /* border: 1px solid #209541; */
  border-radius: 4px;
  cursor: pointer;
}

.filter_item_icon {
  margin-left: 24px;
}

.filter_item_name {
  margin-left: 14px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 9px;
}

.projects_container {
  max-width: 1275px;
  margin: auto;
  margin-top: 60px;
  /* height: 508.77px; */
  position: relative;
}

.next_btn,
.prev_btn {
  width: 55px;
  height: 55px;
  position: absolute;
  top: 41%;
  border: none;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.prev_btn {
  left: -75px;
}

.next_btn {
  transform: rotate(180deg);
  right: -75px;
}

.see_more_btn {
  width: 190px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #209541;
  border: 1px solid #209541;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  margin-top: 40px;
}

.see_more_btn:hover {
  color: #209541;
}

@media screen and (max-width: 550px) {
  .filter {
    width: 100%;
    height: 143px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .projects_container {
    margin-top: 42px;
  }

  .see_more_btn {
    width: 160px;
    height: 42px;
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  .filter {
    height: 135px;
  }

  .filter_item {
    width: 238px;
    height: 57px;
  }

  .projects_container {
    margin-top: 39px;
  }

}