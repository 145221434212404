.container{
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
}
.view_comment{
    height: 60px;
    background: #fff;
    padding-left: 33px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}