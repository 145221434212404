.amenities-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -20px;
  background: #ffffff57;
  cursor: pointer;
}
.amenities-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  left: -30px;
  background: #ffffff;
}
.amenities-slider-small-action-holder {
  display: none;
}
.project-card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;

  letter-spacing: 0.05em;
  margin-bottom: 6px;
  color: #000000;
}
.project-card-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;

  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
}

@media (max-width: 1060px) {
  .amenities-slider-action-holder {
    display: none;
  }
  .amenities-slider-small-action-holder {
    display: flex;
    justify-content: center;
  }
}

.banner_container_1 img{
  width: 100%;
}