.other_projects-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 209px !important;
  left: -38px !important;
  background: #ffffff57;
  cursor: pointer;
}

.other_projects-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 209px !important;
  left: -10px !important;
  background: #ffffff;
}

.other_projects-slider-small-action-holder {
  display: none;
}

.other_projects-card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  margin-bottom: 6px;
  color: #000000;
}

.other_projects-card-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: green !important;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px !important;
  height: 40px !important;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: green;
  /* border: green; */
}

.other-project__card {
  width: 408px;
  margin: auto;
}

@media screen and (max-width:1280px) {
  .other_projects-carousal-left-chevron-btn{
    left: -10px !important;
  }
  .other_projects-carousal-right-chevron-btn{
    left: -39px !important;
  }
}
@media screen and (max-width:1280px) {
  .other-project__card {
    width: 350px;
  }
}

@media (max-width: 1060px) {
  .other_projects-slider-action-holder {
    display: none;
  }

  .other_projects-slider-small-action-holder {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}

@media (max-width: 650px) {
  .projects-filter-container {
    flex-direction: column;
    align-items: center;
  }

  /* .project-filter-farmland {
    min-width: 296px;
  } */
  .other_projects-carousal-left-chevron-btn-responsive {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
  }

  .other_projects-carousal-right-chevron-btn-responsive {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
  }
  .other-project__card {
    width: 339px;
    margin: auto;
}
}