.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}
.darkBg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.78);
}
.wrapper {
  width: 883px;
  height: 469px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: white;
  background-repeat: no-repeat;
  background-position-y: bottom;
  display: flex;
  flex-direction: column;
  animation-name: leftToRightMoving;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
@keyframes leftToRightMoving {
  0% {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  }
  100% { 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
}
.cross_btn {
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
}
.image_container {
  align-self: flex-end;
}
.form_container {
  margin-top: 40px;
  width: 336.85px;
  align-self: flex-end;
  margin-right: 36.7px;
}
.form_title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #090909;
}
.form_row {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 81%;
    background: #fff !important;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .cross_btn {
    top: 20px;
    right: 20px;
  }
  .form_container {
    margin-top: 45px;
    margin-right: 0;
    width: 336.85px;
    align-self: center;
  }
}
@media screen and (max-width: 500px) {
  .form_container {
    width: 73%;
  }
}
@media screen and (max-width: 380px) {
  .wrapper {
    width: 90%;
  }
  .form_container {
    width: 82%;
  }
}
