.ql-container{box-sizing: border-box;
font-family: Helvetica, Arial, sans-serif;
font-size: 16px;
height: 100%;
margin: 0px;

position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
    visibility: hidden;
    display: none;
}