.connect-container {
  width: 400px;
  max-height: 200px;
  top: 200px;
  right: 100px;
}
.connect-info-form-container {
  background-color: white;
  box-shadow: 0px 2px 20px rgb(67 81 1 / 22%);
  border: 1px solid #ccc;
  padding: 20px;
  /* width: 373px; */
  width: 415px;
  height: 519px;
  position: relative;
  padding-top: 10px;
}
.connect-info-header {
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
  color: #090909;
  padding-bottom: 8px;
}
.car-image {
  width: 100%;
  position: absolute;
  left: 0;
}
.car-image img {
  width: 100%;
}

#other-project {
  position: sticky;
  z-index: 10;
}
.image-color {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  top: 100px;
  margin: 7px;
  left: 64%;
}
.propert-container {
  min-height: 400px;
  max-width: 100%;
  margin-left: 20px;
}
.container-props {
  width: 80%;
}
.current-image {
  max-width: 700px;
  max-height: 600px;
}
.image-title {
  font-weight: 500;
  font-size: 26px;
  line-height: 21px;
  color: #212325;
}
.image-title-container {
  display: flex;

  width: 50%;
  flex-direction: column;
}
.image-approve-container {
  /* border-top: 0.3px solid rgba(32, 149, 65, 0.5); */
  margin: -25px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* margin-top: -30px; */
  margin-top: 4px;
}
.tab-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212325;
}
.amenities-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 145px);
  justify-content: space-around;
  grid-gap: 16px 12px;
  margin-top: 30px;
}
.broucher_container {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  /* height: 100px; */
  justify-content: center;
}

.broucher_image-container {
  width: 300px;
  height: 300px;
}

.download_button {
  position: absolute;
  z-index: 2;
  top: 130px;
}
.download_button:active {
  transform: scale(1.01);
}
.download_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 323px;
  height: 52px;
  background: #209541;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  cursor: pointer;
  color: #ffffff;
}
.location_icon {
  position: absolute;
  left: -20px;
  background-color: white;
  width: 36.14px;
  height: 36.14px;
  border-radius: 25.1417px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.location-title {
  font-size: 18px;
}
.where_from {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 14px;
  /* identical to box height, or 71% */

  letter-spacing: 0.06em;

  /* primary */

  color: #212325;
  padding-bottom: 20px;
  padding-left: 8px;
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  height: 100%;
  margin: 0px;
  line-height: 24px !important;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
  display: none;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 24px !important;
  height: 100%;
  padding-top: 0px !important;
  padding-bottom: 12px !important;
  padding-left: 2px !important;
  padding-left: 10px !important;
  letter-spacing: 0.04em !important;
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .propert-container {
    min-height: 400px;
    width: 100%;
    margin-left: 10px;
  }
  .container-props {
    width: 60%;
  }
  .connect-container {
    width: 400px;
    top: 300px;
    right: 100px;
  }

  .image-color {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 100px;
    margin: 7px;
    left: 62%;
    width: 500px;
  }
  .current-image {
    max-width: 550px;
    max-height: 400px;
  }
}
@media (max-width: 778px) {
  .propert-container {
    margin-left: 0;
  }
  .image-color {
    display: none;
  }
  .container-props {
    width: 100%;
  }
  .current-image {
    max-width: 250px;
    max-height: 400px;
  }
  .image-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 10px;
    color: #212325;
  }
  .image-title-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .image-approve-container {
    /* border-top: 0.3px solid rgba(32, 149, 65, 0.5); */
    margin: -25px;
    padding: 30px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  .tab-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 5px;
    color: #212325;
    gap: 30px;
  }
  /* .amenities-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
  } */

  .amenities-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 115px);
    justify-content: space-around;
    grid-gap: 16px 12px;
    margin-top: 30px;
  }

  .broucher_image-container {
    width: 150px;
    height: 150px;
  }
  .download_button {
    position: absolute;
    z-index: 2;
    top: 50px;
  }
  .download_option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 223px;
    height: 42px;
    background: #209541;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    color: #ffffff;
  }
  .location-title {
    font-size: 12px !important;
    width: 100%;
  }
  .where_from {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 71% */

    letter-spacing: 0.06em;

    /* primary */

    color: #212325;
    padding-bottom: 20px;
    padding-left: 8px;
  }
}
@media (min-width: 1301px) and (max-width: 1600px) {
  .propert-container {
    min-height: 400px;
    width: 100%;
    margin-left: 10px;
  }
  .container-props {
    width: 65%;
  }
  .image-color {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 180px;
    margin: 7px;
    left: 67%;
    width: 100px;
  }
  .current-image {
    max-width: 700px;
    max-height: 400px;
  }
}
@media (min-width: 1601px) and (max-width: 2500px) {
  .propert-container {
    min-height: 400px;
    width: 100%;
    margin-left: 10px;
  }
  .container-props {
    width: 65%;
  }
  .image-color {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 180px;
    margin: 7px;
    left: 67%;
    width: 100px;
  }
  .current-image {
    max-width: 760px;
    max-height: 400px;
  }
  .broucher_image-container {
    width: 500px;
    height: 500px;
  }
  .download_button {
    position: absolute;
    z-index: 2;
    top: 230px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .connect-info-form-container{
    padding-top: 2px;
  }
}