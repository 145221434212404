.container {
  margin: 10px;
  margin-top: 19px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 25px;
}

.map_container {
  width: 100%;
  height: 338px;
  margin: auto;
  background: green;
}
.map_image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.near_locations{
    margin-top: 29px;
}