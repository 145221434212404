.main_wrapper {
  /* padding: 29px 39px 30px 29px; */
  position: relative;
}
.wrapper {
  display: flex;
  /* background: red; */
  align-items: center;
}
.avatar_container {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  background: ghostwhite;
  border-radius: 50%;
}
.avatar_container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.comment_container {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  width: -webkit-fill-available;
  padding: 22px 25px 0 25px;
  position: relative;
}
.bottom_bg {
  position: absolute;
  width: 100%;
  height: 46px;
  background: rgba(0, 111, 215, 0.03);
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 27px 27px;
  z-index: 1;
}
.comment_name {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #2e384d;
}
.comment_time {
  margin-left: 15px;
  color: #0b0b1278;
}
.main_comment {
  margin-top: 10px;
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  z-index: 2;
  position: relative;
  margin-top: 18px;
}
.actions_group1 {
  display: flex;
}
.like_action {
  /* margin-top: -3px; */
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dislike_action {
  margin-left: 26px;
  cursor: pointer;
  /* margin-bottom: -5px; */
  display: flex;
  align-items: center;
}
.dislike_action img {
  transform: rotate(180deg);
}
.likeCount,
.disLikeCount {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  margin-left: 12.53px;
  color: rgba(0, 0, 0, 0.5);
}

.more_actions {
  cursor: pointer;
}
