.container {
  position: absolute;
  width: 130px;
  height: 72px;
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 16px rgba(104, 110, 117, 0.12);
  border-radius: 8px;
  z-index: 2;
  right: 0;
  bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container div {
  width: 109px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #101a32;
  cursor: pointer;
}
