.award-carousal-left-chevron-btn {
  position: relative;
  /* z-index: 999; */
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 76px; */
  left: -65px;
  background: #ffffff57;
  cursor: pointer;
}

.award-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  /* z-index: 999; */
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -55px;
  left: 15px;
  background: #ffffff;
}

.award {
  width: 100%;
  justify-content: center;
  background-color: #fbfff5;
  background-image: url("../../../public/assets/Awards/bagimage.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* .trendingProjects-cards{
    display: flex;
    justify-content: center;
    height: 700px;
    background-color: #FBFFF5;
} */

.tp-img {
  width: 375px;
  height: 300px;
}

.award-receice-icon {
  justify-content: center;
  text-align: center;
  /* margin-top: 55px; */
}

.award-receice-logo {
  justify-content: center;
  text-align: center;
  /* margin-top: -205px; */
}

.award-receive-title {
  text-align: center;
  font-weight: 500;
  font-size: 27px;
  line-height: 21px;
  color: #212325;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* .slick-dots li {
  display: none !important;
} */
.award-receive-subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #828485;
  margin-bottom: 30px;
  width: 330px;
  margin-bottom: 24px;
  height: 46px;
}

.award-container {
  max-width: 2500px;
  padding-bottom: 3px;
  padding-top: 0px;
  overflow: hidden;
}

.award-header {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 0px;
  padding: 66px 0px 0px 0px;
}

.award-sub-header {
  text-align: center;
  font-size: 18px;
  line-height: 47px;
  color: #828485;
  margin-bottom: 57px;
}

.award-project {
  text-align: -webkit-center !important;
}
.image-awards {
  /* height: 400px;
  width: 400px; */
  width: 315.78px;
  height: 254px;
  object-fit: contain;
  /* margin-top: 100px; */
}
.award-slider-action-holder {
  /* margin-top: -10px; */
  /* margin-top: 20px; */
}
.award-slider-small-action-holder {
  /* display: none; */
}
@media (max-width: 850px) {
  .tp-img {
    margin-left: 15px;
  }

  .award {
    background-image: none;
  }
  .trendingproject-header {
    font-size: 28px;
    margin-top: 30px;
  }

  .trendingproject-sub-header {
    font-size: 18px;
    line-height: 35px;
  }

  .award-container {
    max-width: 2500px;
    /* padding-bottom: 72px; */
    padding-bottom: 0;
    padding-top: 86px;
    overflow: hidden;
  }
  .award-receive-title {
    font-weight: 500;
    font-size: 18.2878px;
    line-height: 14px;
  }

  .award-receive-subtitle {
    font-weight: 400;
    font-size: 10.8372px;
    line-height: 15px;
    /* or 137% */

    text-align: center;
    width: 254px;
  }

  .award-header {
    font-weight: 500;
    font-size: 28px;
    line-height: 147.7%;
    /* or 41px */
    align-items: flex-start;
    text-align: center;
    margin-bottom: 5px;
    padding: 0px;
  }

  .award-sub-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #212325;
    opacity: 0.7;
    margin-bottom: 30px;
  }
  .image-awards {
    height: 300px;
    width: 225px;
    /* margin-top: 100px; */
  }
  .award-slider-action-holder {
    margin-top: 20px;
    display: none;
  }
  .award-slider-small-action-holder {
    display: flex;
    justify-content: center;
    /* padding-bottom: 20px; */
  }
  .award-carousal-left-chevron-btn-responsive {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
  }
  .award-carousal-right-chevron-btn-responsive {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
  }
}

/* 
@media (max-width:850px){
   
  
    
    .trendingproject-sub-header{
        text-align: center;
        font-size: 18px;
        line-height: 40px;
        color: #828485;
        margin-bottom: 15px;
    }
    .trendingProjects-card{
        background: white;
        border-radius: 15px; 
        width: 375px;
        height: 415px;
        margin: 10px;
      
        box-shadow: 0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #D0DAF4;
    }
    .trendingProjects-card-title{
        font-size: 18px;
        text-align: left;
        font-weight: 500;
        margin-left: 12px;
        margin-top: 11px;
    }
    .trendingProjects-icon{
        margin: -40px 336px;
    }
    .trendingProjects-card-paragraph{
        font-size: 13px;
        display: flex;
        font-weight: 400;
        text-align: center;
        color: rgb(140, 143, 145);
        margin:7px 12px;
        text-align: left;
    }
    
}
 */
