.amenities-carousal-left-chevron-btn {
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px !important;
  left: -69px !important;
  background: #ffffff57;
  cursor: pointer;
}
.amenities-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  z-index: 999;
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px !important;
  left: 22px !important;
  background: #ffffff;
}
.amenities-slider-small-action-holder {
  display: none;
}
.amenities-card-title {
  margin-bottom: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 141.7%;
  letter-spacing: 0.05em;
}
.project-card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  color: #000000;
}
.project-card-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
}

@media (max-width: 1280px) {
  .amenities-slides-container{
    width: 700px;
    margin: auto;
  }
}
@media (max-width: 1060px) {
  .amenities-slider-action-holder {
    display: none;
  }
  .amenities-slider-small-action-holder {
    margin-top: 43px;
    padding-bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amenities-carousal-left-chevron-btn-responsive{
    border: 1px solid #E3E3E3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
  }
  .amenities-carousal-right-chevron-btn-responsive{
    border: 1px solid #E3E3E3;
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #ffffff57; */
    background: #fff;
    cursor: pointer;
  }
}
@media (max-width: 778px) {
  .amenities-slides-container{
    width: 100%;
  }

}
@media (max-width: 1392px) {
  .amenities-slides-container{
    width: 100%;
  }

}