.header-container {
  box-shadow: 0px -2px 2px rgba(62, 83, 3, 0.3),
    0px 1px 3px rgba(79, 107, 4, 0.2);
  position: relative;
  position: sticky;
  top: 0;
  left: 0;
  background: white !important;
  z-index: 500;
}
.main_nav_logo {
  display: block;
  width: 165px;
  height: 57.62px;
  margin-top: 22px;
}

.header-container-content {
  max-width: 1440px;
  margin: auto;
}
.login-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 8px;
}

.header-primary-container {
  height: 97.33px;
  display: flex;
  padding-left: 70px;
}

.header-secondary-container {
  display: flex;
  height: 56px;
}
.dropdown-image_container {
  height: 56px;
  width: 25rem;
  position: relative;
  left: 34rem;
}
.dropdown {
  float: left;
  color: black;
  text-decoration: none;
  font-size: 16px;
  align-items: center;

  margin-right: 10px;
}

.dropdown1 {
  float: right;
  line-height: 16px;
  color: black;
  text-decoration: none;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding-top: 14px;

  padding-left: 10px;
}

.dropdown2 {
  float: right;
  align-items: center;
}
.dropdown-logo {
  width: 62px;
  height: 37.6px;
}
.dropdown-logo2 {
  width: 48px;
  height: 38.24px;
}

.header-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212325;
  padding: 0 2px;
  cursor: pointer;
}

.header-ham {
  display: none;
}
.header-ham1 {
  display: none;
}
.header-link-mobile {
  display: none;
}
.dropdown_image {
  position: absolute;
  top: -30px;
}
.nav-bar {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-top: 10px;
}


@media (max-width:480px) {
  .header-primary-container {
    margin-right: 0px !important;
  }
}
@media (max-width: 899px) {
  .nav-bar {
    display: none;
  }
  .header-ham {
    display: flex;
    align-self: center;
  }
  .header-ham1 {
    display: block;
  }
  .main_nav_logo {
    display: block;
    width: auto;
    height: 38.62px;
    margin-top: 22px;
  }
  .login-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;

    border-top: 1px solid #fff;
    position: absolute;

    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0;
    padding-bottom: 18px;
    width: 80%;
    background: #081b95;
  }

  .nav-links {
    padding: 10px;
    width: 100%;
    display: table;
  }
  .header-link-mobile {
    display: block;
    text-align: center;
    justify-content: center;
    padding: 6px;
    color: black;
    font-size: 10px;
    line-height: 10px;
  }

  .header-link {
    display: none;
  }

  .header-primary-container {
    padding-left: 20px;
    text-align: center;
    /* padding: 14px; */
    height: 84px;
    margin-right: -38px;
  }
}

@media (max-width: 1024px) {
  .header-seccondary-container {
    position: relative;
    left: 24rem !important;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .dropdown-image_container {
    height: 56px;
    width: 28rem;
    position: relative;
    left: 29rem;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .dropdown-image_container {
    height: 56px;
    width: 28rem;
    position: relative;
    left: 42rem;
  }
}
@media (min-width: 1300px) and (max-width: 1699px) {
  .dropdown-image_container {
    height: 56px;
    width: 28rem;
    position: relative;
    left: 51rem;
  }
}
@media (min-width: 1700px) and (max-width: 2009px) {
  .dropdown-image_container {
    height: 56px;
    width: 28rem;
    position: relative;
    left: 47rem;
  }
}
