.our-customer-speak-modal .anticon {
  color: red;
}
.our-customer-speak-modal .ant-modal-close-x {
  margin-top: -11px;
  margin-right: -11px;
}

.our-customer-speak-container {
  display: flex;
  margin: 0px;
}

.our-customer-speak-card {
  display: flex;
  height: 298px;
  max-height: 318px;
  width: 95%;
  border-radius: 25px;
  border: 1px solid #eaeaef;
  text-align: left;
  background: #fff;
  padding: 0px 20px;
}

.our-customer-speak-quota {
  display: flex;
  opacity: 0.4;
  padding-right: 0px;
  margin-left: 30px;
  max-width: 360px;
  overflow: hidden;
}

.our-customer-speak-tag-line {
  max-width: 383px;
  height: 72px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(55, 55, 55, 0.7);
  margin-top: 10px;
  margin-left: 10px;
}

.our-customer-speak-sub-tag-line {
  /* width: 353px; */
  height: 72px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  display: flex;
  text-align: end;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
  color: #209541;
  z-index: -1;
}
.our-customers-speak-slider-action-holder {
  display: block;
  margin-top: 20px;
}
.our-customers-speak-carousal-left-chevron-btn {
  position: relative;
  /* z-index: 999; */
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 76px; */
  left: -65px;
  background: #ffffff57;
  cursor: pointer;
}
.our-customers-speak-carousal-right-chevron-btn {
  cursor: pointer;
  position: relative;
  /* z-index: 999; */
  border: 1px solid #999;
  border-radius: 100px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -55px;
  left: 15px;
  background: #ffffff;
}
@media (min-width: 1000px) and (max-width: 1150px) {
  .our-customer-speak-card {
    display: flex;
    height: 298px;
    max-height: 318px;
    width: 670px;
    border-radius: 25px;
    border: 1px solid #eaeaef;
    text-align: left;
    background: #fff;
    padding: 20px;
  }
}
.our-customer-speak-mobile-tag-line {
  /* font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    max-width: 1440px;
    padding: 0px 10px; */
  display: none;
}
@media (max-width: 830px) {
  .our-customers-speak-slider-action-holder{
    display: none;
  }
  .our-customar-speaks {
    transform: scale(0.6);
  }

  .our-customer-speak-container {
    height: 336px;
    margin-top: -72px;
    margin-bottom: -28px;
  }
  .our-customer-speak-card {
    display: block;
    height: auto;
    max-height: 1440px;
    width: 670px;
    /* padding-bottom: ; */
    margin-top: -20px;
  }

  .our-customer-speak-quota {
    max-width: 1440px;
    padding-top: 10px;
  }

  .our-customer-speak-image {
    width: 100%;
    margin: 0px !important;
  }

  .our-customer-speak-tag-line {
    /* font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    max-width: 1440px;
    padding: 0px 10px; */
    display: none;
  }
  .our-customer-speak-mobile-tag-line {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-width: 1440px;
    padding: 0px 10px;
    margin-left: 10px;
    flex-direction: column;
  }
  .our-customer-speak-sub-tag-line {
    margin-left: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    text-align: none;
    align-items: center;
    justify-content: flex-end;
  }
}

/* @media (max-width: 375px) {
  .our-customar-speaks {
    transform: scale(0.5);
  }
} */
