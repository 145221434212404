.container {
  width: 327px;
  height: 251px;
  background: #fff;
  box-shadow: 0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #d0daf4;
  border-radius: 5px;
  padding: 30px;
}
.container:hover {
  box-shadow: 0px 2px 20px rgba(67, 81, 1, 0.22);
}
.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  margin-top: 20px;
  color: #212325;
}
.subTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  margin-top: 5px;
  color: #212325;
  opacity: 0.6;
}

.view_link {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.view_link div {
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #209541;
}
.view_link img {
  width: 12px;
  height: 12px;
}

@media (max-width: 778px) {
  .container {
    margin-top: 20px;
  }
}

@media (max-width: 1280px) {
  .container {
    width: 250px;
    justify-content: space-evenly;
    height: auto;
    padding-bottom: 30px;
  }
  .subTitle{
   height: 63px;     
  }
}
