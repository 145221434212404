.container {
  position: absolute;
  right: 80px;
  bottom: 70px;
  z-index: 1000;
}
.container img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .container {
    width: 160px;
    right: 65px;
    bottom: 50px;
    z-index: 1000;
  }
}
@media screen and (max-width: 375px) {
  .container {
    width: 140px;
    /* right: 52px;
    bottom: 37px; */
    right: 23px;
    bottom: 28px;
    z-index: 1000;
  }
}
