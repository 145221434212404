.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 778px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


@media (max-width: 1280px) {
    .container {
      justify-content: space-evenly;
    }
  }