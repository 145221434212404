.container {
  width: 408px;
  /* min-height: 508.77px; */
  background: #ffffff;
  box-shadow: 0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #d0daf4;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
  padding-bottom: 20px;
}

.main_image_container {
  width: 100%;
  height: 235px;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0.5px solid #8080804d;
  border-top: 4px solid #209541;
  /* padding-bottom: 10px; */
}

.main_image_container img {
  width: 100%;
  height: 100%;
  /* object-fit: conatin; */
  /* object-fit: cover; */
  border-radius: 6px 6px 0px 0px;
}

.logo_container {
  width: 119px;
  height: 50px;
  margin-top: 16.9px;
  margin-left: 16px;
}

.logo_container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  color: #212325;
  margin: 0;
  margin-left: 19.57px;
  margin-top: 15.64px;
}

.description {
  /* width: 338px; */
  min-height: 83px;
  margin-left: 19.57px;
  font-weight: 400;
  font-size: 16.14px;
  line-height: 21px;
  color: #212325;
  opacity: 0.7;
  margin-top: 11.9px;
}

.calltoaction_conatiner {
  margin-left: 16.08px;
  width: 100%;
}

.link_btn {
  width: 132.26px;
  height: 40px;
  background: #209541;
  border: 0.657074px solid #209541;
  border-radius: 3.53386px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 7px;
  color: #fff;
  /* padding: 16px 29.13px; */
  text-transform: uppercase;
  ;
}

.link_btn:hover {
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .description {
    font-size: 15px;
    margin-right: 15px;
  }

  .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 88%;
    height: auto;
    padding-bottom: 20px;
  }

  .title {
    font-size: 16px;
  }

  .description {
    width: 84%;
    font-size: 14px;
  }

  .calltoaction_conatiner {
    margin-top: 27px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    width: 60%;
    height: auto;
    padding-bottom: 20px;
  }

  .description {
    margin-top: 6px;
  }
}

@media screen and (max-width: 470px) {
  .container {
    width: 75%;
    height: auto;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 375px) {
  .container {
    width: 85%;
    height: auto;
    padding-bottom: 20px;
  }
}