/* .why-us-card {
  display: flex;
  margin-right: 70px;
  margin-bottom: 35px;
} */
/* .why-us {
  min-height: 780px;
  display: flex;
}  */
.container {
  flex: 1;
  background-size: 50% 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: #fbfff5;
  background-image: url(../../assets/images/whyus-bg.png);
}
@media (min-width: 1000px) and (max-width: 1350px) {
  .card {
    display: flex;
    margin-right: 100px;
    margin-bottom: 35px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 750px) {
  .why-us-card {
    margin-left: 0;
  }
  .why-us {
    min-height: 180px !important;
  }
  .container {
    flex: 1;
    background-size: contain !important;
    margin: 0;
    height: 1000px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #fbfff5;
    background-image: url(../../assets/images/ModernHouse.png);
  }
}

.why-us-transparency {
  font-weight: 500;
  font-size: 30px;
  color: "#212325";
  margin-bottom: 10px;
}

.why-us-transparency-sub-title {
  margin-top: 10;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  max-width: 479px;
  color: rgba(33, 35, 37, 0.7);
}
.why-us-transparency-number {
  font-weight: 700;
  font-size: 60px;
  line-height: 75.7%;
  height: 45px;
  color: #ffab26;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-right: 2px solid #ffab26;
}

@media only screen and (max-width: 1280px) {
.container {
  background-size: 50% 70%;
}
}
@media only screen and (max-width: 320px) {
  .why-us-transparency {
    font-size: 22px;
    margin-bottom: 11px;
  }
}

@media only screen and (max-width: 375px) {
  .why-us-transparency {
    font-size: 22px;
    margin-bottom: 11px;
  }

  .why-us-transparency-sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-width: none;
  }
  .why-us-transparency-number {
    font-weight: 700;
    font-size: 42px;
    line-height: 75.7%;
  }
}

@media only screen and (max-width: 430px) {
  .why-us-transparency {
    font-size: 22px;
    margin-bottom: 11px;
  }

  .why-us-transparency-sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-width: none;
  }
  .why-us-transparency-number {
    font-weight: 700;
    font-size: 42px;
    line-height: 75.7%;
  }
}

.page-section-header-container {
  padding-top: 86px;
  padding-bottom: 55px;
  text-align: center;
}
.page-section-header-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 22px;
}

.page-section-header-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212325;
  opacity: 0.7;
}

@media (max-width: 430px) {
  .page-section-header-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 147.7%;
    text-align: center;
  }
  .page-section-header-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
}
@media (max-width: 375px) {
  .page-section-header-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 147.7%;
    text-align: center;
  }
  .page-section-header-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
}
